<template>
  <div class="vertical-spaces">
    <div class="d-flex justify-content-between mx-3 mt-4">
      <div class="d-flex">
        <ion-button class="back-button" @click="back">
          <ion-icon class="icon" :icon="arrowBackOutline" />
        </ion-button>
      </div>
      <ion-skeleton-text animated class="title w-25" />
      <div></div>
    </div>

    <span class="d-flex my-3 mx-3">
      <ion-skeleton-text :animated="true" class="spaces ml-1" />
      <ion-skeleton-text :animated="true" class="spaces ml-1" />
    </span>
  </div>

  <ion-skeleton-text animated class="title w-25 py-2 pb-3 mt-3" />

  <span class="d-flex flex-column mb-3">
    <ion-skeleton-text :animated="true" class="chat-loader ml-1" />
    <ion-skeleton-text :animated="true" class="chat-loader ml-1" />
    <ion-skeleton-text :animated="true" class="chat-loader ml-1" />
  </span>
</template>

<script lang="ts" setup>
import { arrowBackOutline } from 'ionicons/icons';

const router = useRouter();

const back = () => {
  router.go(-1);
};
</script>

<style lang="sass" scoped>
.chat-loader
  width: 100%
  height: 120px
.spaces
  width: 140px
  height: 150px
.set-margin
  margin-top: -5px
.vertical-spaces
  background: #c6c8d6

.back-button
  --background: #8168b7
  --border-radius: 10px
  height: 34px
.icon
  font-size: 14px
.title
  font-size: 24px
  font-weight: bold
</style>
